<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="size"
    color="info"
  >
    <div class="row">
      <CCol lg="4" md="4" sm="12" xs="12">
        <CInput
          label="Monto Pendiente"
          v-model="item.pending"
          :disabled="true"
        ></CInput>
      </CCol>
      <CCol lg="4" md="4" sm="12" xs="12">
        <CInput
          label="Monto a pagar"
          v-model="amount"
          placeholder="Monto a pagar"
          v-on:keypress="isNumber($event)"
          @blur="mtd_out_focus"
        ></CInput>
      </CCol>
      <CCol lg="4" md="4" sm="12" xs="12">
        <CInput
          label="Saldo"
          v-model="cp_saldo"
          placeholder="Saldo"
          :disabled="true"
        ></CInput>
      </CCol>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
      <CButton @click="mtd_commit" color="info" :disabled="cp_button_aceptar"
        >Aceptar</CButton
      >
    </template>
  </CModal>
</template>

<script>
import { bus } from "../../../main";
export default {
  name: "c-modal-delete",
  data() {
    return {
      amount: "",
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    size: String,
  },
  computed: {
    cp_saldo: function () {
      if (this.amount > 0) {
        return parseFloat(this.item.pending - this.amount).toFixed(2);
      }
      return this.item.pending;
    },
    cp_button_aceptar: function () {
        if (parseFloat(this.amount) <= parseFloat(this.item.pending) && this.amount > 0) return false;
        return true;
    },
  },
  created() {
    this.amount="";
  },
  methods: {
    mtd_close() {
      this.$emit("close_save_charge");
    },
    mtd_commit() {
      this.$emit("mtd_save_charge",this.amount,this.item.Id);
      this.amount="";
    },
    mtd_out_focus: function () {
      if (parseFloat(this.amount) > parseFloat(this.item.pending)) {
        bus.$emit("alert", {
          color: "warning",
          message: "EL MONTO A PAGAR NO DEBE SER MAYOR AL PENDIENTE!!",
        });
        this.amount = 0;
      } else {
        this.amount =
          this.amount > 0 ? parseFloat(this.amount).toFixed(2) : 0.0;
      }
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>