<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de clientes"
          icon="fas fa-users"
          btn_name="cliente"
          :actions="true"
          :button_new="false"
          :withActions="'10%'"
          :myButtons="myButtons"
          @mtd_charge="mtd_charge"
          @mtd_view="mtd_view"
          @mtd_view_prd="mtd_view_prd"
        />
      </CCol>
    </CRow>

    <!-- modal charge -->
    <cModalCharge
      title="Registrar pago"
      :boo_modal="modal_charge.boo"
      :item="modal_charge.item"
      @close_save_charge="close_save_charge"
      @mtd_save_charge="mtd_save_charge"
    ></cModalCharge>

    <!-- modal view -->
    <cModalView
      title="Lista de pagos"
      :boo_modal="modal_view.boo"
      :item="modal_view.item"
      @close_view="close_view"
    ></cModalView>

     <!-- ver productos -->
    <cModalViewPrd
      title="Lista de productos"
      :boo_modal="modal_view_prd.boo"
      :item="modal_view_prd.data"
      @close_view_prd="close_view_prd"
    ></cModalViewPrd>
  </div>
</template>

<script>
const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "client", label: "Cliente", _style: "width:20%;" },
  { key: "total", label: "Total", _style: "width:15%;" },
  // { key: "initial", label: "Inicial", _style: "width:10%;" },
  { key: "paid", label: "Monto Pagado", _style: "width:10%;" },
  { key: "pending", label: "Pendiente", _style: "width:10%;" },
  { key: "expiration", label: "Vence en", _style: "width:10%;" },
  { key: "status", label: "Estado", _style: "width:10%;" },
];

import { mapActions } from "vuex";
import { bus } from "../../main";

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalCharge from "../../components/shared/charge/cModalCharge.vue";
import cModalView from "../../components/shared/charge/cModalView.vue";
import cModalViewPrd from "../../components/shared/charge/cModalViewPrd.vue";

export default {
  name: "v-proccess-charge",
  components: { CTableWrapper, cModalCharge, cModalView,cModalViewPrd },
  data() {
    return {
      prefix: "charge",
      fields,
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Pagar",
          action: "mtd_charge",
          icon: "fas fa-cash-register",
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Ver pagos",
          action: "mtd_view",
          icon: "fas fa-eye",
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Ver productos",
          action: "mtd_view_prd",
          icon: "fas fa-book",
        },
        
      ],
      modal_charge: {
        boo: false,
        item: [],
      },
      modal_view: {
        boo: false,
        item: [],
      },
      modal_view_prd: {
        boo: false,
        data: [],
      },
    };
  },
  computed: {},
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.map((item, order) => {
            return { ...item, order };
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    /** pagos */
    mtd_charge: function (item) {
      this.modal_charge.boo = true;
      this.modal_charge.item = item;
    },
    close_save_charge: function () {
      this.modal_charge.boo = false;
    },
    mtd_save_charge: function (amount, sale_id) {
      let post = {
        sale_id: sale_id,
        amount: amount,
      };
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/store",
        token: this.$store.getters.get__token,
        params: post,
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "PAGO REGISTRADO CORRECTAMENTE!!",
            });
            this.close_save_charge();
            this.mtd_getdata();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: "ERROR AL REGISTRAR!!",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** lista */
    mtd_view: function(item){
        this.modal_view.boo = true;
        this.modal_view.item =item;
    },
    close_view: function(){
        this.modal_view.boo=false;
    },
    /** prds */
    mtd_view_prd: function(item){
      console.log(item.prds);
      this.modal_view_prd.boo=true;
      this.modal_view_prd.data = item.prds;
    },
    close_view_prd: function(){
      this.modal_view_prd.boo=false;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>